import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import { Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import QuoteBox from '@/components/QuoteBox';
import BankStatementInsights from '@/assets/solutions/bank-statement-insights.webp';
import { UncontainAndSpaced } from '@/components/Uncontain';
import ReduceRisk from '@/components/Solutions/ReduceRisk';
import Provider, { BlueText } from '@/components/Solutions/Provider';
import DemoForm from '@/components/DemoForm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Provider mdxType="Provider">
      <Box align="center" paddingY={3} mdxType="Box">
        <h4 {...{
          "id": "unrivalled-insights-that-you-can-depend-on",
          "style": {
            "position": "relative"
          }
        }}>{`Unrivalled Insights that you can depend on.`}<a parentName="h4" {...{
            "href": "#unrivalled-insights-that-you-can-depend-on",
            "aria-label": "unrivalled insights that you can depend on permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <Box align="left" mdxType="Box">
        <p>{`TaleFin delivers Australia's most accurate and highest quality consumer financial insights. Using secure access to customer consented financial data and powerful metrics that uncover valuable decision information, Our consumer financial insights enable clients to reduce consumer financial risk, create upsell opportunities, learn consumers retail preferences and empower clients to make informed decisions.`}</p>
      </Box>
      <Grid container justifyContent="center" alignItems="center" textAlign="center" paddingY={6} mdxType="Grid">
        <Grid item sm={12} md={3} mdxType="Grid">
          <img src={BankStatementInsights} height={150} width={130} />
        </Grid>
        <Grid item sm={12} md={9} mdxType="Grid">
          <QuoteBox mdxType="QuoteBox">
            <p>{`We have enhanced the risk assessment overview of consumers by providing bank statement scoring and metrics. The incorporation of bank statement-derived metrics can enhance decision-making, thereby improving the assessment of a consumer's ability to repay a loan.`}</p>
          </QuoteBox>
        </Grid>
      </Grid>
      <p>{`Utilising financial data with analysis and leveraging essential metrics to make significant improvements that allow your financial insights to grow. Financial analysis and reporting offer a level of understanding that helps businesses remain compliant while streamlining their income and expenditures.`}</p>
      <Box paddingTop={3} paddingBottom={8} mdxType="Box">
        <UncontainAndSpaced mdxType="UncontainAndSpaced">
          <Grid container justifyContent="center" alignItems="center" spacing={4} mdxType="Grid">
            <Grid align="center" item xs={12} mdxType="Grid">
              <h5 {...{
                "id": "reduce-lending-risk-with-smarter-categorisation-and-metrics",
                "style": {
                  "position": "relative"
                }
              }}>{`Reduce lending risk with smarter categorisation and metrics.`}<a parentName="h5" {...{
                  "href": "#reduce-lending-risk-with-smarter-categorisation-and-metrics",
                  "aria-label": "reduce lending risk with smarter categorisation and metrics permalink",
                  "className": "anchor after"
                }}><svg parentName="a" {...{
                    "aria-hidden": "true",
                    "focusable": "false",
                    "height": "16",
                    "version": "1.1",
                    "viewBox": "0 0 16 16",
                    "width": "16"
                  }}><path parentName="svg" {...{
                      "fillRule": "evenodd",
                      "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                    }}></path></svg></a></h5>
            </Grid>
            <Grid item sm={12} md={8} mdxType="Grid">
              <Box textAlign="left" mdxType="Box">
                <p>{`We're able to provide bespoke metrics and personalised analysis templates. This means we can produce financial insights that offer risk assessment options tailored to your specific business, lending policies, or assessor requirements. This includes BNPL metrics, wage-only metrics, risk behaviours, trend charts, enhanced dishonour matching just to name a few, with new metrics being added all the time.`}</p>
                <p>{`TaleFin provides the most sophisticated and unique Financial Insights that our customers tell us are the most accurate on the market.`}</p>
              </Box>
            </Grid>
          </Grid>
        </UncontainAndSpaced>
      </Box>
      <p>{`We give you all the tools you need to optimise customer experiences and transform financial insights. We can combine financial data from multiple accounts to get a complete view of your customer's finances. Identify all income sources, examine expenses and discover liabilities and risks with fast access to critical financial data and insights.`}</p>
      <Box paddingTop={3} textAlign="center" mdxType="Box">
        <BlueText variant="h5" mdxType="BlueText">
Improve your customer's experience and increase approvals of good loans with the right information.
        </BlueText>
      </Box>
    </Provider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      